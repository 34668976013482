import React from "react";
import loadable from "@loadable/component";
import {
  CbButton,
  CbCopy,
  CbImages,
  // CbLead,
  CbLogoGrid,
  CbQuote,
  CbVideo,
} from "@molecules";
import { Container } from "@atoms";

const AccordionList = loadable(() => import("@organisms/AccordionList"));
const ApplicationForm = loadable(() => import("@organisms/ApplicationForm"));
const ArticleGrid = loadable(() => import("@organisms/ArticleGrid"));
const CardCarousel = loadable(() => import("@organisms/CardCarousel"));
const CareersList = loadable(() => import("@organisms/CareersList"));
const FormSelect = loadable(() => import("@organisms/FormSelect"));
const CaseStudyCarousel = loadable(() =>
  import("@organisms/CaseStudyCarousel")
);
const CaseStudyGrid = loadable(() => import("@organisms/CaseStudyGrid"));
const ContainedGrid = loadable(() => import("@organisms/ContainedGrid"));
const Cta = loadable(() => import("@organisms/Cta"));
const FeatureGrid = loadable(() => import("@organisms/FeatureGrid"));
const FlowChart = loadable(() => import("@organisms/FlowChart"));
const Hero = loadable(() => import("@organisms/Hero"));
const ImageCopy = loadable(() => import("@organisms/ImageCopy"));
const ImageCopyGrid = loadable(() => import("@organisms/ImageCopyGrid"));
const ImpactGrid = loadable(() => import("@organisms/ImpactGrid"));
const LeadCopy = loadable(() => import("@organisms/LeadCopy"));
const LogoCarousel = loadable(() => import("@organisms/LogoCarousel"));
const LogoCarouselAlt = loadable(() => import("@organisms/LogoCarouselAlt"));
const MapBlock = loadable(() => import("@organisms/MapBlock"));
const PeopleGrid = loadable(() => import("@organisms/PeopleGrid"));
const RibbonContainer = loadable(() => import("@organisms/RibbonContainer"));
const SectionHeading = loadable(() => import("@organisms/SectionHeading"));
const ServiceCarousel = loadable(() => import("@organisms/ServiceCarousel"));
const ServiceGrid = loadable(() => import("@organisms/ServiceGrid"));
const SimpleList = loadable(() => import("@organisms/SimpleList"));
const VideoCarousel = loadable(() => import("@organisms/VideoCarousel"));
const TestimonialCarousel = loadable(() =>
  import("@organisms/TestimonialCarousel")
);
const TwoColumn = loadable(() => import("@organisms/TwoColumn"));
const Definition = loadable(() => import("@organisms/Definition"));
const ReviewGrid = loadable(() => import("@organisms/ReviewGrid"));
const ButtonGroup = loadable(() => import("@organisms/ButtonGroup"));

const PageBuilder = ({ blocks }) => {
  return (
    <>
      {blocks?.map((b, i) => {
        const { type, uid } = b;
        switch (type) {
          case "accordionList":
            return <AccordionList {...b} key={uid} />;
          case "applicationForm":
            return <ApplicationForm {...b} key={uid} />;
          case "articleGrid":
            return <ArticleGrid {...b} key={uid} />;
          case "blockQuote":
            return <CbQuote {...b} key={uid} />;
          case "button":
            return <CbButton {...b} key={uid} className="md:mb-32" />;
          case "cardCarousel":
            return <CardCarousel {...b} key={uid} />;
          case "careersList":
            return <CareersList {...b} key={uid} />;
          case "caseStudyCarousel":
            return <CaseStudyCarousel {...b} key={uid} />;
          case "caseStudyGrid":
            return <CaseStudyGrid {...b} key={uid} />;
          case "containedGrid":
            return <ContainedGrid {...b} key={uid} />;
          case "copy":
            return <CbCopy {...b} key={uid} />;
          case "clientCarousel":
            return <LogoCarousel {...b} key={uid} />;
          case "cta":
            return <Cta {...b} key={uid} />;
          case "featureGrid":
            return <FeatureGrid {...b} key={uid} />;
          case "flowChart":
            return <FlowChart {...b} key={uid} />;
          case "hero":
            return <Hero {...b} key={uid} />;
          case "images":
            return <CbImages {...b} key={uid} />;
          case "imageCopy":
            return <ImageCopy {...b} key={uid} />;
          case "imageCopyGrid":
            return <ImageCopyGrid {...b} key={uid} />;
          case "impactGrid":
            return <ImpactGrid {...b} key={uid} />;
          case "leadCopy":
            return <LeadCopy {...b} key={uid} />;
          case "logoGrid":
            return <CbLogoGrid {...b} key={uid} />;
          case "map":
            return <MapBlock {...b} key={uid} />;
          case "peopleGrid":
            return <PeopleGrid {...b} key={uid} />;
          case "ribbons":
            return <RibbonContainer {...b} key={uid} />;
          case "sectionHeading":
            return <SectionHeading {...b} key={uid} />;
          case "serviceCarousel":
            return <ServiceCarousel {...b} key={uid} />;
          case "serviceGrid":
            return <ServiceGrid {...b} key={uid} />;
          case "simpleList":
            return <SimpleList {...b} key={uid} />;
          case "testimonialCarousel":
            return <TestimonialCarousel {...b} key={uid} />;
          case "twoColumnContent":
            return <TwoColumn {...b} key={uid} />;
          case "video":
            return <CbVideo {...b} key={uid} />;
          case "formSelect":
            return <FormSelect {...b} key={uid} />;
          // new components 20224
          case "logoCarousel":
            return <LogoCarouselAlt {...b} key={uid} />;
          case "definition":
            return <Definition {...b} key={uid} />;
          case "videoCarousel":
            return <VideoCarousel {...b} key={uid} />;
          case "reviewGrid":
            return <ReviewGrid {...b} key={uid} />;
          case "buttonGroup":
            return (
              <Container className="relative z-10 mb-16 md:-mt-8">
                <ButtonGroup {...b} key={uid} />
              </Container>
            );
          default:
            return <div>{type}</div>;
        }
      })}
    </>
  );
};

PageBuilder.defaultProps = {};

export default PageBuilder;
